import {createRouter, createWebHistory} from 'vue-router';
import Landing                          from '@/views/Landing.vue';
// import SignIn                           from '@/views/SignIn.vue';
import SignIn                           from '@/views/SignIn2.vue';
import SignUp                           from '@/views/SignUp.vue';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            component: Landing
        },
        {
            path: '/signin',
            name: 'signin',
            component: SignIn
        },
        {
            path: '/signup',
            name: 'signup',
            component: SignUp
        },
        {
            path: '/about',
            name: 'about',
            component: () => import('@/views/About.vue')
        },
        {
            path: '/privacy',
            name: 'privacy',
            component: () => import( '@/views/Privacy.vue')
        },
        {
            path: '/terms',
            name: 'terms',
            component: () => import( '@/views/Terms.vue')
        },
        {
            path: '/mission',
            name: 'mission',
            component: () => import( '@/views/Mission.vue')
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import( '@/views/Dashboard.vue'),
            children: [
                {
                    path: 'account',
                    name: 'account',
                    component: () => import( '@/views/dashboard/Account.vue')
                },
                {
                    path: 'platforms',
                    name: 'platforms',
                    component: () => import( '@/views/dashboard/Platforms.vue'),
                    children: [
                        {
                            path: 'youtube',
                            name: 'youtube',
                            component: () => import('@/views/dashboard/YouTube.vue'),
                            children: [
                                {
                                    path: 'login',
                                    beforeEnter(to, from, next) {
                                        window.location.href = 'https://clicklikegive.com/youtube/auth'
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    path: 'creators',
                    name: 'creators',
                    component: () => import( '@/views/dashboard/Creators.vue')
                },
                {
                    path: 'budget',
                    name: 'budget',
                    component: () => import( '@/views/dashboard/Budget.vue')
                },
                {
                    path: 'activity/:when',
                    name: 'activity',
                    component: () => import( '@/views/dashboard/Activity.vue')
                },
                {
                    path: 'settings',
                    name: 'settings',
                    component: () => import( '@/views/dashboard/Settings.vue')
                }
            ]
        },
        {
            path: '/err/:msg',
            name: 'error',
            component: () => import('@/views/Error.vue')
        }
    ]
});

router.beforeEach((to, from) => {

});
export default router;
