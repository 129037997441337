<script setup></script>

<template>
  <div class='flex-col text-center'>
    <div>
      <span class='text-xs'>By using this app, you agree to ClickLikeGive.com's</span>
      <router-link class='text-xs font-bold text-link'
                   to='/terms'>Terms of Service
      </router-link>
      <span class='text-xs text-center'> and </span>
      <router-link class='text-xs font-bold text-link'
                   to='/privacy'>Privacy Policy
      </router-link>
      <span class='text-xs text-white'>.</span>
      <br/></div>
    <span class='mt-auto text-xs sm:mt-0'>&copy; 2021. ClickLikeGive. All rights reserved.</span>
  </div>
</template>
