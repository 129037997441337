<script setup>
import Footer      from '@/components/Footer.vue';
import {useRouter} from 'vue-router';
import {ref}       from 'vue';
import axios       from 'axios';

const router = useRouter();
const emailAddress = ref(null);
const password = ref(null);
const apiClient = axios.create({
    baseURL: 'https://api.clicklikegive.com/auth',
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});
const errors = ref([]);

const signUp = async () => {
    try {
        const response = await apiClient.post('/signup', {email: emailAddress.value, password: password.value});
        if (response.status === 201) {
            router.push('/dashboard');
        } else {
            console.error(`${response.status} ${response.statusText}`);
        }
    } catch (error) {
        if (error.response) {
            console.log(`status: ${error.response.status}`);
            if (error.response.status === 409) {
                console.log(`errors: ${JSON.stringify(error.response.data)}`);
                errors.value = error.response.data.errors;
                router.push('/signup');
            } else {
                console.error(error);
            }
        } else if (error.request) {
            console.error(error.request);
        } else {
            console.error('Error:', error);
        }
    }
};

const passwordVisibilityType = ref('password');
const passwordVisibilityToggleText = ref('show');

const togglePasswordVisibility = () => {
    passwordVisibilityType.value = passwordVisibilityType.value === 'password' ? 'text' : 'password';
    passwordVisibilityToggleText.value = passwordVisibilityToggleText.value === 'show' ? 'hide' : 'show';
};

</script>

<template>
  <section class=' w-full min-h-screen flex flex-col justify-between items-center p-16'>
    <h1 class='text-center mt-auto text-2xl font-bold sm:text-3xl md:text-4xl'>
      Welcome to ClickLikeGive.com </h1>
    <p class='mt-8 leading-relaxed mb-8'>
      Thanks for taking the time to sign up to support your favorite content creators. We respect your time, it is more valuable than ours, there are way more of you than us!<br/><br/> That is why
      <a href='/mission#pii'>we only ask information as it is needed</a> and <a class=''
                                                                                href='/privacy'>will never share your personal information</a>.
    </p>

    <div class='w-full flex flex-col mt- space-y-8'>
      <ul v-if='errors'>
        <li v-for='(error, index) in errors'
            class='text-red-500'
            :key='index'>{{ error }}
        </li>
      </ul>
      <div class='flex flex-col'>
        <label for='email_address'
               class='flex-1 block text-sm font-medium text-gray-700'> Email Address </label>

        <input type='email'
               id='email_address'
               name='email_address'
               placeholder='Enter a verifiable email to use to as your login'
               class='mt-1 flex-1 rounded-md border-black text-sm text-gray-700 shadow-sm my-4'
               aria-describedby='email-helper-text'
               v-model='emailAddress'/>
        <span id='email-helper-text'
              class='text-xs text-black'><a href='/privacy'>Your information privacy is an important responsibility to us.</a></span>

      </div>

      <div class='flex flex-col'>
        <label for='password_input'
               class='block text-sm font-medium text-gray-700'> Password </label>
        <div class='flex relative w-full'>
          <div class='absolute inset-y-0 right-0 flex items-center px-2 pb-3'>
            <label id='password_visibility_label'
                   class='bg-black rounded px-2 py-1 text-sm text-white font-mono cursor-pointer'
                   for='password_visibility_toggle'>{{ passwordVisibilityToggleText }}</label>
            <input class='hidden'
                   id='password_visibility_toggle'
                   type='checkbox'
                   @click='togglePasswordVisibility'/>
          </div>
          <input class='mt-1 flex-1 rounded-md border-black text-sm text-gray-700 shadow-sm my-4'
                 id='password_input'
                 :type='passwordVisibilityType'
                 autocomplete='off'
                 placeholder='Enter in a memorable but unguessable password'
                 v-model='password'/>
        </div>
        <span class='text-xs text-black'><a href='https://xkcd.com/936'>A longer password that is memorable is more secure that cryptic special characters!</a></span>
      </div>

      <div class='flex flex-col'>
        <label for='referral_code'
               class='block text-sm font-medium text-gray-700'> Referred By </label>

        <input type='text'
               id='referral_code'
               name='referral_code'
               placeholder='Enter a Referral Code if you have one.'
               class='mt-1 rounded-md border-black text-sm text-gray-700 shadow-sm my-4'/>
        <span class='text-xs text-black'>Otherwise leave blank.</span>
      </div>

      <div class='w-full'>
        <p class='text-sm text-gray-500'>
          By creating an account, you agree to our <a href='#'
                                                      class='text-gray-700 underline'> terms and conditions </a> and
          <a href='#'
             class='text-gray-700 underline'>privacy policy</a>. </p>
      </div>

      <div class='flex flex-col'>
        <button class='inline-block shrink-0 rounded-md border border-black px-12 py-3 text-sm font-medium text-black mb-8'
                @click='signUp'>
          Create an account
        </button>
      </div>
    </div>
    <Footer class=' mt-auto'/>
  </section>
</template>

<style scoped
       lang='postcss'>

</style>
