<template>
    <main class="flex flex-col px-6 pt-20 pb-2 mx-auto w-full bg-stone-200 max-w-[480px]">
        <h1 class="mt-60 text-2xl font-extrabold leading-7 text-zinc-800">Create New Account</h1>
        <form @submit.prevent="handleSubmit">
            <div class="mt-7 text-xs font-bold leading-5 text-zinc-600">
                <label for="email">Email Address</label>
            </div>
            <div class="flex gap-2 p-4 mt-2.5 text-base leading-6 text-gray-400 rounded-md border border-red-600 border-solid">
                <input id="email" v-model="email" type="email" class="flex-1" placeholder="Your username here" aria-label="Email Address" required />
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/4427bee168a319b328147a738a64f7a043bb16f8ea8f44b87af66aa9c716b68e?apiKey=7605a7f6f7aa4bc0ab0556c4559132ad&" alt="" class="shrink-0 w-6 aspect-square" />
            </div>
            <div class="mt-5 text-xs font-bold leading-5 text-zinc-600">
                <label for="password">Password</label>
            </div>
            <div class="flex gap-2 p-4 mt-2.5 text-base leading-6 text-gray-400 rounded-md border border-red-600 border-solid">
                <input id="password" v-model="password" type="password" class="flex-1" placeholder="Enter Password" aria-label="Password" required />
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/57f43a89e8afd2eb5d18c27969b54b5019bf15b880cdf33d5269cf9cb21f68c4?apiKey=7605a7f6f7aa4bc0ab0556c4559132ad&" alt="" class="shrink-0 w-6 aspect-square" />
            </div>
            <div class="mt-5 text-xs font-bold leading-5 text-zinc-600">
                <label for="confirmPassword">Confirm Password</label>
            </div>
            <div class="flex gap-2 p-4 mt-2.5 text-base leading-6 text-gray-400 rounded-md border border-red-600 border-solid">
                <input id="confirmPassword" v-model="confirmPassword" type="password" class="flex-1" placeholder="Re-Enter your password" aria-label="Confirm Password" required />
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/57f43a89e8afd2eb5d18c27969b54b5019bf15b880cdf33d5269cf9cb21f68c4?apiKey=7605a7f6f7aa4bc0ab0556c4559132ad&" alt="" class="shrink-0 w-6 aspect-square" />
            </div>
            <div class="flex gap-3 justify-center items-center self-center mt-10">
                <div class="shrink-0 self-stretch w-4 h-4 bg-sky-500 rounded-full" aria-hidden="true"></div>
                <div class="shrink-0 self-stretch my-auto w-3 h-3 bg-gray-300 rounded-full" aria-hidden="true"></div>
                <div class="shrink-0 self-stretch my-auto w-3 h-3 bg-gray-300 rounded-full" aria-hidden="true"></div>
            </div>
            <button type="submit" class="justify-center items-center px-16 py-4 mt-3.5 text-base font-semibold leading-6 text-center rounded-md border border-solid bg-slate-200 border-zinc-600 text-zinc-800">
                Create Account
            </button>
        </form>
        <div class="shrink-0 self-center mt-12 bg-black h-[5px] rounded-[100px] w-[135px]" aria-hidden="true"></div>
    </main>
</template>

<script setup>
import { ref } from 'vue';

const email = ref('');
const password = ref('');
const confirmPassword = ref('');

/**
 * Handle form submission
 */
const handleSubmit = () => {
    // Perform form validation and submission logic here
    console.log('Form submitted');
    console.log('Email:', email.value);
    console.log('Password:', password.value);
    console.log('Confirm Password:', confirmPassword.value);

    // Reset form fields
    email.value = '';
    password.value = '';
    confirmPassword.value = '';
};
</script>